<template>
  <van-action-sheet class="goodsSearchUp" v-model:show="show">
    <div class="headBox dipcen">
      <span>
        {{ $fanyi("筛选") }}
      </span>
      <div class="closeBtn dipcen" @click="show = false">
        <img :src="require('@/assets/icon/tanchaunbgchahao.png')" alt="" />
      </div>
    </div>
    <div class="priceFilteringBox">
      <div class="searchItem">
        <div class="priceFilteringTitle">{{ $fanyi("价格") }}</div>
        <div class="priceRangeBox">
          <input
            type="text"
            class="inputBox"
            :placeholder="$fanyi('最低价格')"
            v-model="oform.priceStart"
            oninput="value=value.replace(/[^0-9]/g,'')"
            @input="!isNaN(oform.priceStart) ? '' : (oform.priceStart = null)"
          />
          <span class="delimiter"></span>
          <input
            type="text"
            class="inputBox"
            v-model="oform.priceEnd"
            :placeholder="$fanyi('最高价格')"
            oninput="value=value.replace(/[^0-9]/g,'')"
            @input="!isNaN(oform.priceEnd) ? '' : (oform.priceEnd = null)"
          />
        </div>
      </div>
      <div class="searchItem" v-if="$parent.shoptype == '1688'">
        <div class="priceFilteringTitle">{{ $fanyi("筛选") }}</div>
        <div class="searchbtnList">
          <div
            class="filter_button"
            :class="{ active: find_owned('jxhy') }"
            @click="chiose_filter('jxhy')"
          >
            {{ $fanyi("严选商品") }}
          </div>
          <div
            v-if="$route.query.type != 'imgSearch'"
            class="filter_button"
            :class="{ active: oform.regionOpp == 'jpOpp' }"
            @click="
              oform.regionOpp != 'jpOpp'
                ? (oform.regionOpp = 'jpOpp')
                : (oform.regionOpp = '')
            "
          >
            <div class="hotIcon">
              <img :src="require('@/assets/icon/hotScource.gif')" alt="" />
            </div>
            {{ $fanyi("日本热卖") }}
          </div>
          <div
            class="filter_button"
            :class="{ active: oform.regionOpp == 'krOpp' }"
            v-if="$route.query.type != 'imgSearch'"
            @click="
              oform.regionOpp != 'krOpp'
                ? (oform.regionOpp = 'krOpp')
                : (oform.regionOpp = '')
            "
          >
            <div class="hotIcon">
              <img :src="require('@/assets/icon/hotScource.gif')" alt="" />
            </div>
            {{ $fanyi("韩国热卖") }}
          </div>
        </div>
      </div>
      <div class="searchItem" v-if="$parent.shoptype == '1688'">
        <div class="priceFilteringTitle">{{ $fanyi("工厂") }}</div>
        <div class="searchbtnList">
          <div
            class="filter_button"
            :class="{ active: find_owned('certifiedFactory') }"
            @click="chiose_filter('certifiedFactory')"
          >
            {{ $fanyi("严选工厂") }}
          </div>
          <div class="filter_button">
            {{ $fanyi("店铺评分") }}
            <dropDown
              ref="eDropDown"
              @change="itemChange($event)"
              :itemlist="xingji_list"
              placeholder=" "
              showName="key"
            >
            </dropDown>
          </div>
        </div>
      </div>
      <div class="searchItem" v-if="$parent.shoptype == '1688'">
        <div class="priceFilteringTitle">{{ $fanyi("服务保障") }}</div>
        <div class="searchbtnList">
          <div
            class="filter_button"
            :class="{ active: find_owned('shipInToday') }"
            @click="chiose_filter('shipInToday')"
          >
            {{ $fanyi("当日发货") }}
          </div>
          <div
            class="filter_button"
            :class="{ active: find_owned('shipln24Hours') }"
            @click="chiose_filter('shipln24Hours')"
          >
            {{ $fanyi("24小时发货") }}
          </div>
          <div
            class="filter_button"
            :class="{ active: find_owned('shipln48Hours') }"
            @click="chiose_filter('shipln48Hours')"
          >
            {{ $fanyi("48小时发货") }}
          </div>
          <div
            class="filter_button"
            :class="{ active: find_owned('noReason7DReturn') }"
            @click="chiose_filter('noReason7DReturn')"
          >
            {{ $fanyi("7天内无理由退货") }}
          </div>
        </div>
      </div>
      <div class="goodsOtherSearchBox" v-if="$parent.shoptype == '1688'">
        <div
          class="searchItem"
          v-for="(searchItem, searchIndex) in otherList"
          :key="searchIndex"
        >
          <div class="priceFilteringTitle">{{ searchItem.translateName }}</div>
          <div class="searchbtnList">
            <div
              v-for="(
                searchchildrenItem, searchchildrenIndex
              ) in searchItem.children"
              :key="searchchildrenIndex"
              class="filter_button"
              :class="{
                active: find_owned_snId(searchItem.id, searchchildrenItem.id),
              }"
              @click="chiose_snId(searchItem, searchchildrenItem)"
            >
              {{ searchchildrenItem.translateName }}
            </div>
          </div>
        </div>
      </div>

      <div class="btnList">
        <button @click="reset" class="success">{{ $fanyi("重置") }}</button>
        <button @click="confirm" class="danger">{{ $fanyi("确定") }}</button>
      </div>
    </div>
  </van-action-sheet>
</template>
<script setup>
import { getCurrentInstance, ref, defineEmits } from "vue";
import dropDown from "./dropdown.vue";
defineEmits(["save_chiose"]);

const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
const show = ref(false);
const oform = ref({
  filter: [],
});
const otherList = ref([]);
//------------------------ methods -------------------------------------
const open = (inform) => {
  show.value = true;
  oform.value = JSON.parse(JSON.stringify(inform));

  setTimeout(() => {
    proxy.$refs.eDropDown.setData(inform.xingji);
  }, 300);
};

const find_owned_snId = (name, v) => {
  if (oform.value.snId[name] && oform.value.snId[name] == v) {
    return true;
  } else {
    return false;
  }
};
const chiose_snId = (searchItem, searchchildrenItem) => {
  if (
    oform.value.snId[searchItem.id] &&
    oform.value.snId[searchItem.id] == searchchildrenItem.id
  ) {
    oform.value.snId[searchItem.id] = "";
  } else {
    oform.value.snId[searchItem.id] = searchchildrenItem.id;
  }
  // console.log(oform.value.snId);
};

const confirm = () => {
  proxy.$emit("save_chiose", oform.value);
  show.value = false;
};

const reset = () => {
  proxy.$emit("save_chiose", "reset");
  show.value = false;
};

const xingji_list = ref([
  {
    key: proxy.$fanyi("全部"),
    value: "",
  },
  {
    key: proxy.$fanyi("5星"),
    value: "totalEpScoreLv1",
  },
  {
    key: proxy.$fanyi("4.5星-5.0星"),
    value: "totalEpScoreLv2",
  },
  {
    key: proxy.$fanyi("4星-4.5星"),
    value: "totalEpScoreLv3",
  },
]);

const itemChange = (v) => {
  oform.value.xingji = v.value;
};

const find_owned = (v) => {
  let fdata = oform.value.filter.find((item) => item == v);
  return fdata;
};

const chiose_filter = (v) => {
  let fdataIndex = oform.value.filter.findIndex((item) => item == v);
  if (fdataIndex != -1) {
    oform.value.filter.splice(fdataIndex, 1);
  } else {
    oform.value.filter.push(v);
  }
};

const getkeywordSNQuery = () => {
  if (proxy.$route.query.keyword) {
    proxy.$api
      .keywordSNQuery({ keyword: proxy.$route.query.keyword })
      .then((res) => {
        ////console.log('事件名',res)
        if (res.code != 0) return proxy.$message.error(res.data.msg);
        //接下来的操作
        res.data.forEach((data) => {
          data.children.forEach((element) => {
            let id = element.id;
            id = id.split(":");
            element.id = id[1];
          });
        });
        otherList.value = res.data;
      });
  }
};
getkeywordSNQuery();
//------------------------ pageLoad -------------------------------------
defineExpose({ open });
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.headBox {
  height: 88px;
  padding: 25px 0;
  text-align: center;
  top: 0px;
  position: sticky;
  background-color: white;
  z-index: 1;
  span {
    font-size: 30px;
    line-height: 1;
  }
  .closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 25px 30px;
    img {
      width: 18px;
      height: 18px;
    }
  }
}
.goodsOtherSearchBox {
  padding-bottom: 200px;
}
.priceFilteringBox {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .searchItem {
    padding: 30px 0 20px;
    margin: 0 30px 10px;
    border-bottom: 1px solid #dfdfdf;
    .priceFilteringTitle {
      line-height: 1;
      font-size: 28px;
      color: #999999;
      margin-bottom: 20px;
    }
    .priceRangeBox {
      display: flex;
      align-items: center;
      padding-top: 1px;
      .inputBox {
        border-radius: 6px;
        border: 1px solid #dfdfdf;
        width: 180px;
        height: 53px;
        font-size: 28px;
      }
      .delimiter {
        height: 1px;
        width: 13px;
        background-color: #999;
        margin: 0 20px;
      }
      button {
        width: 108px;
        height: 60px;
        background: #b4272b;
        border-radius: 6px;
        font-size: 24px;
        color: #fff;
        margin-left: 30px;
        &:active {
          opacity: 0.7;
        }
      }
    }
    .searchbtnList {
      display: flex;
      flex-wrap: wrap;

      .filter_button {
        padding: 0 20px;
        height: 53px;
        background: #f2f2f2;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 28px;
        position: relative;
        margin: 10px 20px 10px 0;
        .hotIcon {
          position: absolute;
          top: -30px;
          right: 2px;
          img {
            width: 32px;
            height: 20px;
          }
        }
      }
      .filter_button.active {
        color: #b4272b;
      }
    }
  }
  .btnList {
    position: sticky;
    bottom: 118px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 65px;
    margin-top: auto;
    background-color: white;
    button {
      width: 280px;
      height: 70px;
      border-radius: 6px;
      font-size: 28px;
      &:active {
        opacity: 0.7;
      }
    }
    button.success {
      background: #27b470;
      color: white;
    }
    button.danger {
      background-color: #b4272b;
      color: white;
    }
  }
}
</style>
<style lang="scss">
.goodsSearchUp {
  .van-action-sheet__content {
    height: 80vh;
    display: flex;
    flex-direction: column;
  }
  .van-action-sheet__header {
    display: none;
    text-align: center !important;
    font-size: 30px;
    height: 88px;
    padding-top: 45px;
    line-height: 1;
    .van-icon-cross {
      padding-top: 25px;
      font-size: 25px;
    }
  }
}
</style>
