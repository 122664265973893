<template>
  <div>
    <div class="noData">
      <h3 class="noDataH3">关键词： {{ $store.state.searchInfo }}</h3>
      <img class="noDataImg" :src="require('@/assets/icon/sp.png')" alt="" />
      <span class="noDataSpan">{{ $fanyi("没有找到相关商品") }}</span>
      <span class="noDataSpan">{{
        $fanyi("您可以尝试更换关键词或者图片进行查找。")
      }}</span>
    </div>
    <div class="recommendForYou">
      <div class="JianYi">
        <span>{{ $fanyi("为你推荐") }} </span>
      </div>
      <div class="goodsList">
        <div
          class="listOpt"
          v-for="goodsItem in goodsList"
          @click="
            $fun.toCommodityDetails(goodsItem.goodsId, goodsItem.shopType)
          "
          :key="goodsItem.sales"
        >
          <van-image lazy-load class="goodsImg" :src="goodsItem.imgUrl">
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
          <p class="goodsTitle" v-if="goodsItem.titleT">
            {{ goodsItem.titleT }}
          </p>
          <p class="goodsPrice" v-if="goodsItem.goodsPrice">
            <span class="en">
              {{ goodsItem.goodsPrice }}{{ $fanyi("元") }}
            </span>
            <span class="cn"
              >({{
                (goodsItem.goodsPrice * $store.state.exchangeRate).toFixed(0)
              }}{{ $fanyi("円") }})</span
            >
          </p>
          <van-skeleton v-else :row="2" />
        </div>
      </div>
    </div>
    <div class="tishiFont">
      <van-loading type="spinner" size="20" />
      <span>{{ $fanyi("加载中") }}</span>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
const goodsList = ref([{}, {}, {}, {}, {}]);
const autoplay = ref(true);
const translation_name = ref("");
const chinese_name = ref("");
const timeout = ref(false); //加载节流
const page = ref(1);
//------------------------ methods -------------------------------------
// 滚动事件
const scroll = () => {
  // 如果这个组件没有显示就不加载这个事件
  if (!proxy.$parent.noDataShow) {
    return;
  }
  let dd = document.documentElement;

  if (dd.scrollTop >= dd.scrollHeight - 3000) {
    hotGoods();
  }
};
const hotGoods = (fir) => {
  // 如果路由不为商品搜索页面或者有显示数据就不加载
  if (timeout.value) {
    return false;
  } else {
    timeout.value = true;
  }
  if (
    proxy.$route.query.name !== "CommoditySearch" &&
    !proxy.$parent.noDataShow
  ) {
    return false;
  }
  if (fir) {
    goodsList.value = [{}, {}, {}, {}, {}, {}, {}, {}];
    page.value = 1;
  }

  proxy.$api
    .youLikeGoods({
      type: 1688,
      page: page.value,
    })
    .then((res) => {
      timeout.value = false;
      ////console.log('事件名',res)
      if (res.code != 0);
      if (Array.isArray(res.data)) {
        res.data = { result: [] };
      }
      //接下来的操作
      if (fir) {
        goodsList.value = res.data.result;
      } else if (res.data.result.length == 0) {
        hotGoods();
      } else {
        res.data.result.forEach((element) => {
          goodsList.value.push(element);
        });
      }

      page.value++;
    });
};
//------------------------ pageLoad -------------------------------------
hotGoods(true);
window.addEventListener("scroll", scroll, true);
defineExpose({});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.noData {
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .noDataH3 {
    margin-bottom: 62px;
    width: 100%;
    font-size: 24px;
    padding-left: 30px;
  }
  .noDataImg {
    width: 297px;
    height: 269px;
    margin-bottom: 42px;
  }
  .noDataSpan {
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    color: #999999;
    text-align: center;
  }
}
.recommendForYou {
  margin-top: 120px;
  padding: 0 30px;
  .JianYi {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 40px;
    margin-bottom: 24px;
    span {
      font-size: 36px;
      font-weight: 500;
      line-height: 40px;
      color: #000000;
    }
  }
  .goodsList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    margin-bottom: 30px;
    .listOpt {
      flex: 0 0 336px;
      margin-bottom: 15px;
      padding-bottom: 15px;
      background: #ffffff;
      border: 1px solid #dfdfdf;
      border-radius: 6px;

      .goodsImg {
        width: 336px;
        height: 336px;
        margin-bottom: 15px;
      }
      .goodsTitle {
        width: 100%;
        font-size: 24px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #212121;
        line-height: 26px;
        text-overflow: -o-ellipsis-lastline;
        padding: 0 20px;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .goodsPrice {
        font-size: 28px;
        padding: 0 20px;
        font-weight: 600;
        color: #b4272b;
        line-height: 1;
        .en {
          font-size: 28px;
        }
        .cn {
          font-size: 24px;
        }
      }
    }
  }
  .van-skeleton {
    padding: 0;
  }
  :deep().van-skeleton__content {
    padding: 0;
    .van-skeleton__row {
      margin-top: 10px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
.tishiFont {
  padding-bottom: 30px;
  display: flex;
  color: #999999;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  span {
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    margin-left: 15px;
  }
}
</style>
