<!-- 下拉框 -->
<template>
  <div class="vue-dropdown default-theme">
    <van-popover v-model:show="showPopover">
      <ul class="my-list-module-box">
        <li
          v-for="(item, index) in itemlist"
          @click="appClick(item, index)"
          :key="index"
          :class="{
            activeBox:
              inputText == item[showName] || showText == item[showName],
          }"
        >
          <span class="list-item-text">{{ item[showName] }}</span>
          <div class="activeIcon">
            <!-- <img
              v-if="inputText == item[showName] || showText == item[showName]"
              src="../../../../assets/order/select_active.png"
              alt=""
            /> -->
          </div>
        </li>
      </ul>
      <!-- 暂无数据 -->
      <div class="tip__nodata" v-show="!itemlist.length">
        {{ $fanyi("暂无数据") }}
      </div>
      <template #reference>
        <div class="search-module clearfix" :class="{ openCss: showPopover }">
          <input
            class="search-text"
            v-if="searchModel"
            @focus="showPopover = true"
            @keyup="search($event)"
            :placeholder="placeholder"
            v-model="inputText"
          />
          <div class="search-text spanBox" v-else>
            <span>{{ showText || inputText }}&nbsp;</span>
          </div>
          <div class="search-icon" :class="{ rotateIcon: showPopover }">
            <!-- <img src="../../../../assets/order/selectIcon.png" alt="" /> -->
          </div>
        </div>
      </template>
    </van-popover>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopover: false,
      inputText: "",
      isShowNone: false,
    };
  },
  props: {
    key: String,
    value: String,
    label: String,
    itemlist: Array,
    placeholder: String,
    searchModel: Boolean,
    showName: String, //使用的参数名
    showText: String, //直接显示的文字
  },
  methods: {
    appClick: function (data, index) {
      this.$emit("change", data);
      this.inputText = data[this.showName];
      this.showPopover = false;
    },
    search: function (e) {
      if (this.itemlist.length === 0) {
        this.isShowNone = true;
      } else {
        this.isShowNone = false;
      }
    },
    inputBlur() {
      this.isShowNone = false;
      this.inputText = "";
    },
    setData(v) {
      // console.log(v);
      let inda = this.itemlist.find((lii) => lii.value == v);
      this.inputText = inda ? inda[this.showName] : v;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.vue-dropdown.default-theme {
  margin-left: 20px;
  &._self-show {
    display: block !important;
  }

  .search-module {
    position: relative;
    width: 240px;
    .search-text {
      width: 100%;
      height: 40px;
      padding: 0 8px;
      background: #ffffff;
      border: 1px solid #e2e2e2;
      border-radius: 4px;
      font-size: 24px;
      display: flex;
      align-items: center;
      &:focus {
        // border-color: #2198f2;
      }
    }
    .spanBox {
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 24px;
        white-space: nowrap;
      }
    }
    .search-icon {
      position: absolute;
      top: 0;
      right: 8px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      img {
        width: 12px;
        height: 12px;
      }
    }
    .search-icon.rotateIcon {
      rotate: 180deg;
    }
  }
}
.tip__nodata {
  font-size: 24px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
}
</style>
<style lang="scss">
.my-list-module-box {
  width: 176px;
  max-height: 200px;
  overflow-y: auto;
  li {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    transition: 0.3s;
    &:active {
      opacity: 0.8;
    }
    .list-item-text {
      font-size: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .activeIcon {
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 12px;
        height: 12px;
      }
    }
  }
  li.activeBox {
    .list-item-text {
      color: #b4272b;
    }
  }
}
// 打开
.openCss {
  .search-text {
    box-shadow: 0px 0px 4px 0px rgba(96, 139, 255, 0.2);
    border: 1px solid #b4272b !important;
  }
}
// 只读
.readOnly {
  .search-text {
    border: 1px solid #e2e2e2 !important;
    box-shadow: none !important;
    background-color: #f8f8f8 !important;
  }
}
</style>
