<!-- 商品搜索页面 -->
<template>
  <headGoBack class="searchPageHead">
    <headBar :onlyTheSearchBoxIsDisplayed="true" />
  </headGoBack>
  <div class="CommoditySearch">
    <backTop />
    <!-- v-if="!!l688list.length || !!taobaolist.length || !!imglist.length" -->
    <div class="sdfsd" v-show="show_productScreening">
      <!-- 商品来源分类 -->
      <div class="CommoditySearch-keyword">
        <div
          class="left-1688 opt"
          :class="{ redcolor: shoptype == '1688' }"
          @click="Commodityswitching('1688')"
        >
          {{ $fanyi("1688") }}
        </div>
        <div
          :class="{ redcolor: shoptype == 'taobao' }"
          class="taobao opt"
          @click="Commodityswitching('taobao')"
        >
          タオバオ＆tmall
        </div>

        <span
          :class="{
            tabposition: shoptype == 'taobao',
            tabposition_Tmall: shoptype == 'tmall',
          }"
        ></span>
      </div>

      <!-- 筛选 -->
      <div class="productScreening" v-if="shoptype == '1688'">
        <!-- 筛选的菜单展示 -->
        <div>
          <div class="ScreenBtnList">
            <div
              class="ScreenBtn"
              :class="{
                comprehensiveactive: searchsort,
              }"
              @click.stop="opentoggle('comprehensive')"
            >
              <span>{{ searchsort_findName(searchsort) }}</span>
              <img
                style="width: 0.17333rem"
                v-if="searchsort"
                :src="require('@/assets/icon/reddrowDown.png')"
                alt=""
              />
              <img
                style="width: 0.17333rem"
                v-else
                :src="require('@/assets/icon/shaixuan.png')"
                alt=""
              />
            </div>
            <div
              v-if="shoptype == '1688' && $route.query.type != 'imgSearch'"
              class="ScreenBtn"
              :class="{ active: regionOpp == 'jpOpp' }"
              @click="
                regionOpp == 'jpOpp' ? (regionOpp = '') : (regionOpp = 'jpOpp');
                filterProducts();
                clickFilterSum();
              "
            >
              <span> {{ $fanyi("日本热卖") }}</span>
            </div>
            <div
              class="ScreenBtn"
              :class="{
                priceFilteringactive: havefil,
              }"
              @click="opentoggle('priceFiltering')"
            >
              <span>{{ $fanyi("筛选") }}</span>
              <img
                style="width: 0.29333rem"
                v-if="havefil"
                :src="require('@/assets/icon/shao.png')"
                alt=""
              />
              <img
                style="width: 0.29333rem"
                v-else
                :src="require('@/assets/icon/shaixunloudou.png')"
                alt=""
              />
            </div>
          </div>
          <van-dropdown-menu ref="dropdownMenu">
            <van-dropdown-item
              title="otrue"
              @open="shaixuanChiose = true"
              @close="shaixuanChiose = false"
            >
              <div class="comprehensiveBtnlistBox">
                <div
                  class="comprehensiveBtn"
                  :class="{ active: searchsort == '' }"
                  @click="
                    searchsort = '';
                    filterProducts();
                  "
                >
                  {{ $fanyi("综合(综合排名)") }}
                </div>
                <div
                  class="comprehensiveBtn"
                  :class="{
                    active: searchsort == 'monthSold',
                  }"
                  @click="
                    searchsort = 'monthSold';
                    filterProducts();
                  "
                >
                  {{ $fanyi("销量最高") }}
                </div>
                <div
                  class="comprehensiveBtn"
                  :class="{
                    active: searchsort == 'desc',
                  }"
                  @click="
                    searchsort = 'desc';
                    filterProducts();
                  "
                >
                  {{ $fanyi("按价格从高到低") }}
                </div>
                <div
                  class="comprehensiveBtn"
                  :class="{
                    active: searchsort == 'asc',
                  }"
                  @click="
                    searchsort = 'asc';
                    filterProducts();
                  "
                >
                  {{ $fanyi("按价格从低到高") }}
                </div>
                <div
                  class="comprehensiveBtn"
                  :class="{
                    active: searchsort == 'rePurchaseRate',
                  }"
                  @click="
                    searchsort = 'rePurchaseRate';
                    filterProducts();
                  "
                >
                  {{ $fanyi("复购率高") }}
                </div>
              </div>
            </van-dropdown-item>
          </van-dropdown-menu>
        </div>

        <!-- 图片搜索的图片 -->
        <div
          class="searchImageBox"
          @click="ontoggle"
          v-if="route.query.type == 'imgSearch'"
        >
          <van-image :src="imgUrl">
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </div>
      </div>
    </div>
    <div v-if="isImgSearch && shoptype == 1688" class="imgserch flex acenter">
      <div class="img-box">
        <van-image
          v-if="!imgUrlList.length"
          :src="$route.query.imgUrl"
          class="active"
        >
          <template v-slot:loading>
            <van-loading size="20" type="spinner" />
          </template>
        </van-image>
        <van-image
          v-for="(item, index) in imgUrlList"
          :key="index"
          :class="{ active: imgUrlAct == index }"
          :src="item.url"
          class="sectionImg"
          @click="setImgUrl(item, index)"
        >
          <template v-slot:loading>
            <van-loading size="20" type="spinner" />
          </template>
        </van-image>
      </div>
      <!-- 右侧商品数量 -->
      <!-- <div class="commodityProducts">
      {{ $t("message.productquantity") }} <span>{{ total }}</span>
      </div> -->
    </div>
    <div class="technicalSupport">
      {{ $fanyi("本次搜索由1688提供部分技术服务⽀持") }}
    </div>
    <!-- 1688 -->
    <van-pull-refresh
      v-if="shoptype == '1688'"
      v-model="refreshing"
      head-height="100"
      :loosing-text="$fanyi('释放即可刷新...')"
      :pulling-text="$fanyi('下拉刷新')"
      :loading-text="$fanyi('加载中')"
      :success-text="$fanyi('加载成功')"
      @refresh="onRefresh"
    >
      <!-- 商品列表区域 -->
      <div class="shoplist">
        <van-list
          v-model:loading="loading"
          v-model:error="error"
          offset="200"
          :error-text="$fanyi('')"
          :finished="finished"
          :loading-text="$fanyi('加载中')"
          :finished-text="$fanyi('')"
          @load="onLoad"
        >
          <div class="list">
            <div
              class="item"
              v-for="item in l688list"
              @click="to_goods_details(item.goodsId, item.shopType)"
              :key="item.goodsId"
            >
              <div class="top-img">
                <van-image :src="item.imgUrl" lazy-load>
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </div>
              <p class="shoptext">
                <span class="icon1688" v-if="item.shopType == '1688'">
                  <img
                    :src="require('@/assets/icon/icon1688.png')"
                    alt=""
                  /> </span
                >{{ item.titleT }}
              </p>
              <div class="price_and_xiaoshou">
                <p class="price">
                  <span>
                    {{ $fun.ceil(item.goodsPrice) }}{{ $fanyi("元") }}</span
                  >
                  ({{
                    $fun.ceil(
                      Number(item.goodsPrice) * $store.state.exchangeRate,
                      0
                    )
                  }}
                  {{ $fanyi("円") }})
                </p>
                <p class="yuexiaoshou">
                  {{ $fanyi("最近售出") }}{{ item.monthSold }}件
                </p>
              </div>
              <!-- 1688原数据没有这几个参数 -->
              <div class="goods_tag_list">
                <div
                  class="tag_item"
                  v-if="filter.find((kk) => kk == 'certifiedFactory')"
                >
                  {{ $fanyi("严选工厂") }}
                </div>
                <div class="tag_item" v-if="regionOpp == 'jpOpp'">
                  {{ $fanyi("日本热卖") }}
                </div>
                <div class="tag_item" v-if="regionOpp == 'krOpp'">
                  {{ $fanyi("韩国热卖") }}
                </div>
              </div>
              <div class="repurchasing">
                {{ $fanyi("复购率") }}<b>{{ item.repurchaseRate || 0 }} </b>%
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </van-pull-refresh>

    <!-- 淘宝 -->
    <van-pull-refresh
      v-if="shoptype == 'taobao'"
      v-model="taobaorefreshing"
      head-height="100"
      :loosing-text="$fanyi('释放即可刷新...')"
      :pulling-text="$fanyi('下拉刷新')"
      :loading-text="$fanyi('加载中')"
      :success-text="$fanyi('加载成功')"
      @refresh="onRefresh"
    >
      <!-- 商品列表区域 -->
      <div class="shoplist">
        <van-list
          v-model:loading="loading"
          v-model:error="error"
          offset="200"
          :error-text="$fanyi('')"
          :finished="taobaofinished"
          :loading-text="$fanyi('加载中')"
          :finished-text="$fanyi('')"
          @load="onLoad"
        >
          <div class="list">
            <div
              class="item"
              v-for="item in taobaolist"
              @click="to_goods_details(item.goodsId, item.shopType)"
              :key="item.goodsId"
            >
              <div class="top-img">
                <van-image :src="item.imgUrl" lazy-load>
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </div>
              <p class="shoptext">
                <span class="icon1688" v-if="item.shopType == '1688'">
                  <img
                    :src="require('@/assets/icon/icon1688.png')"
                    alt=""
                  /> </span
                >{{ item.titleT }}
              </p>

              <p class="price">
                <span>
                  {{ Number(item.goodsPrice).toFixed(2)
                  }}{{ $fanyi("元") }}</span
                >
                ({{
                  (Number(item.goodsPrice) * $store.state.exchangeRate).toFixed(
                    0
                  )
                }}
                {{ $fanyi("円") }})
              </p>
            </div>
          </div>
        </van-list>
      </div>
    </van-pull-refresh>
    <!-- 天猫 -->
    <van-pull-refresh
      v-if="shoptype == 'tmall'"
      v-model="Tmallrefreshing"
      head-height="100"
      :loosing-text="$fanyi('释放即可刷新...')"
      :pulling-text="$fanyi('下拉刷新')"
      :loading-text="$fanyi('加载中')"
      :success-text="$fanyi('加载成功')"
      @refresh="onRefresh"
    >
      <!-- 商品列表区域 -->
      <div class="shoplist">
        <van-list
          v-model:loading="loading"
          v-model:error="error"
          offset="200"
          :error-text="$fanyi('')"
          :finished="Tmallfinished"
          :loading-text="$fanyi('加载中')"
          :finished-text="$fanyi('')"
          @load="onLoad"
        >
          <div class="list">
            <div
              class="item"
              v-for="item in Tmalllist"
              @click="to_goods_details(item.goodsId, item.shopType)"
              :key="item.goodsId"
            >
              <div class="top-img">
                <van-image :src="item.imgUrl" lazy-load>
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </div>
              <p class="shoptext">
                <span class="icon1688" v-if="item.shopType == '1688'">
                  <img
                    :src="require('@/assets/icon/icon1688.png')"
                    alt=""
                  /> </span
                >{{ item.titleT }}
              </p>

              <p class="price">
                <span>
                  {{ Number(item.goodsPrice).toFixed(2)
                  }}{{ $fanyi("元") }}</span
                >
                ({{
                  (Number(item.goodsPrice) * $store.state.exchangeRate).toFixed(
                    0
                  )
                }}
                {{ $fanyi("円") }})
              </p>
            </div>
          </div>
        </van-list>
      </div>
    </van-pull-refresh>
    <!-- :class="{ nodatadd: noDataShow }" -->
    <noData class="nfdsjjk" :class="{ nodatadd: noDataShow }" />
  </div>
  <price_filtering
    :shoptype="shoptype"
    ref="Price_filtering"
    @save_chiose="filterProducts"
  />
  <fooBar />
</template>
<script setup>
import noData from "./components/noData.vue";
import headBar from "../../components/headBar";
import fooBar from "../../components/footBar/index.vue";
import headGoBack from "../../components/headGoBack";
import backTop from "../../components/backTop";
import price_filtering from "./components/price_filtering.vue";
import { computed, getCurrentInstance, onActivated, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { Toast } from "vant";

onActivated(() => {
  // 调用时机为首次挂载以及每次从缓存中被重新插入时
  if (proxy.$route.name != "/productdetails") {
    //判断是不是照片搜索
    if (route.query.searchType == "imgSearch") {
      isImgSearch.value = true;
      imgUrl.value = imgUrlList.value.length
        ? imgUrlList.value[0].url
        : route.query.imgUrl;
    }
  }
});
const { proxy } = getCurrentInstance();
const isImgSearch = ref(false);
const route = useRoute();
const imgUrl = ref(null);
const keywords = ref(""); //搜索关键字
const searchsort = ref(); //商品排序方式 传空是综合 , asc:从低到高 , desc:从高到低
const searchsort_arr = ref([
  {
    key: proxy.$fanyi("综合(综合排名)"),
    returnObj: "",
    returnObj_imgSearch: "",
    value: "",
  },
  {
    key: proxy.$fanyi("销量最高"),
    returnObj: { monthSold: "desc" },
    returnObj_imgSearch: [{ key: "monthSold", value: "desc" }],
    value: "monthSold",
  },
  {
    key: proxy.$fanyi("价格从低到高"),
    returnObj: { price: "asc" },
    returnObj_imgSearch: [{ key: "price", value: "asc" }],
    value: "asc",
  },
  {
    key: proxy.$fanyi("价格从高到低"),
    returnObj: { price: "desc" },
    returnObj_imgSearch: [{ key: "price", value: "desc" }],
    value: "desc",
  },
  {
    key: proxy.$fanyi("复购率高"),
    returnObj: { rePurchaseRate: "desc" },
    returnObj_imgSearch: [{ key: "rePurchaseRate", value: "desc" }],
    value: "rePurchaseRate",
  },
]);
const xingji = ref(""); //星级
const priceStart = ref(); //价格
const priceEnd = ref(); //价格
const regionOpp = ref(""); //是否热卖, jpOpp-日本热卖,krOpp-韩国热卖
const regionOppList = ref([
  { key: proxy.$fanyi("日本热卖"), value: "jpOpp" },
  { key: proxy.$fanyi("韩国热卖"), value: "krOpp" },
]);
const snId = ref({});
const filter = ref([]); //certifiedFactory-认证工厂; shipInToday-当日出货; shipIn24Hours-24小时内出货;shipIn48Hours-48小时内出货;noReason7DReturn-7天无理由退换货
const shaixuanChiose = ref(""); //下拉当前打开的菜单
//判断是不是照片搜索
if (route.query.type == "imgSearch") {
  isImgSearch.value = true;
  imgUrl.value = route.query.imgUrl;
}
// 关键词搜索
if (route.query.keyword) {
  // //console.log(route.query.keyword);
  keywords.value = route.query.keyword;
  proxy.$store.commit("getsearchInfo", route.query.keyword);
  // 进行判断如果路由中有CN 就说明是从分类页面来的需要解码成中文
  if (route.query.CN) {
    keywords.value = proxy.$fun.fromCode(route.query.CN);
  } else {
    keywords.value = proxy.$store.state.searchInfo;
  }
  isImgSearch.value = false;
}

// 监听路由变化看是图片搜索还是关键词
watch(
  () => route,
  (newValue) => {
    if (newValue.name == "CommoditySearch") {
      if (newValue.query.type == "imgSearch") {
        isImgSearch.value = true;
        imgUrl.value = newValue.query.imgUrl;
      } else if (newValue.query.keyword) {
        // 进行判断如果路由中有CN 就说明是从分类页面来的需要解码成中文
        isImgSearch.value = false;
        if (newValue.query.CN) {
          keywords.value = proxy.$fun.fromCode(route.query.CN);
        } else {
          keywords.value = proxy.$store.state.searchInfo;
        }
      }
    }
  },
  { immediate: true }
);
const noDataShow = computed(() => {
  // //console.log(
  //   proxy.$route.query.type == "imgSearch",
  //   !imglist.value || !imglist.value.length
  // );
  return (
    !loading.value &&
    ((!l688list.value.length && shoptype.value == "1688") ||
      (!taobaolist.value.length && shoptype.value == "taobao") ||
      (!Tmalllist.value.length && shoptype.value == "tmall"))
  );
});

const find_regionOpp_name = (v) => {
  let return_data = regionOppList.value.find((reIt) => reIt.value == v);
  return return_data ? return_data.key : v;
};

const searchsort_findName = (v) => {
  let find_result = searchsort_arr.value.find((item) => item.value == v);
  return find_result ? find_result.key : proxy.$fanyi("综合(综合排名)");
};

// 打开筛选的下拉框
const opentoggle = (type) => {
  if (type == "priceFiltering") {
    proxy.$refs.Price_filtering.open({
      priceEnd: priceEnd.value,
      priceStart: priceStart.value,
      regionOpp: regionOpp.value,
      filter: filter.value,
      xingji: xingji.value,
      snId: snId.value,
    });
  } else {
    if (!shaixuanChiose.value) {
      document.getElementsByClassName("van-dropdown-menu__title")[0].click();
    }
  }
};
//  定义为商品类型 shoptye 1688 和淘宝
const shoptype = ref(proxy.$route.query.goodsForm || "1688");

// 商品切换
const Commodityswitching = (type) => {
  shoptype.value = type;
  if (type == "taobao") {
    region.value = "";
    setTimeout(() => {
      if (taobaopage.value == 0) {
        onLoad();
      }
    }, 500);
  }
  if (type == "tmall") {
    region.value = "";
    setTimeout(() => {
      if (Tmallpage.value == 0) {
        onLoad();
      }
    }, 500);
  }
  // if (l688list.value.length == 0 && finished.value == false) {
  //   onLoad()
  // }
  // if (taobaolist.value.length == 0 && taobaofinished.value == false) {
  //   onLoad()
  // }
};
// 商品列表加载数据
const imglist = ref([]); //图片数据
const imgpage = ref(0); //  图片搜索页码
const imgfinished = ref(false);
const imgrefreshing = ref(false);
//  1688数据
const l688list = ref([]);
const page = ref(0); // 1688 页码
const finished = ref(false);
const refreshing = ref(false);
const imgUrlList = ref([]);
const region = ref();
const imgUrlAct = ref(0); //选中的图片

// 淘宝数据
const taobaolist = ref([]);
const taobaopage = ref(0); //  淘宝页码
const taobaofinished = ref(false);
const taobaorefreshing = ref(false);

// Tmall数据
const Tmalllist = ref([]);
const Tmallpage = ref(0); //  淘宝页码
const Tmallfinished = ref(false);
const Tmallrefreshing = ref(false);

const loading = ref(false);
const error = ref(false);
const pageSize = ref(50); //  每页的数量
const fangdou = ref(false); //加载商品防抖

Toast.loading({
  message: proxy.$fanyi("加载中..."),
  forbidClick: true,
  loadingType: "spinner",
  duration: 0,
});
// 触底刷新
const onLoad = async () => {
  window.scrollTo(0, 0);
  if (!fangdou.value) {
    fangdou.value = true;
    let funshopType = shoptype.value; //保存一份函数里的商品来源类型,否则用户在加载的时候切换会淘宝的页面显示1688的数据
    let res = {};
    if (funshopType == "1688") {
      if (page.value == 0) {
        Toast.loading({
          message: proxy.$fanyi("加载中..."),
          forbidClick: true,
          loadingType: "spinner",
          duration: 0,
        });
      }
      page.value += 1;
    }
    if (funshopType == "taobao") {
      if (taobaopage.value == 0) {
        Toast.loading({
          message: proxy.$fanyi("加载中..."),
          forbidClick: true,
          loadingType: "spinner",
          duration: 0,
        });
      }
      taobaopage.value += 1;
    }
    if (funshopType == "tmall") {
      if (Tmallpage.value == 0) {
        Toast.loading({
          message: proxy.$fanyi("加载中..."),
          forbidClick: true,
          loadingType: "spinner",
          duration: 0,
        });
      }
      Tmallpage.value += 1;
    }
    // 判断是图片搜索还是关键词,调用相应的接口
    if (route.query.keyword) {
      let searchData = {
        keywords: keywords.value,
        shop_type: funshopType,
        page:
          funshopType == "tmall"
            ? Tmallpage.value
            : funshopType == "1688"
            ? page.value
            : taobaopage.value,
        pageSize: funshopType == "1688" ? 50 : 20,
      };
      // 筛选只有1688才能用
      if (funshopType == "1688") {
        let searcg_value = searchsort_arr.value.find(
          (sarr) => sarr.value == searchsort.value
        );
        searchData.sort = JSON.stringify(
          searcg_value ? searcg_value.returnObj : ""
        );
        searchData.priceStart = priceStart.value;
        searchData.priceEnd = priceEnd.value;

        if (xingji.value) {
          filter.value.push(xingji.value);
        }
        let filterstr = filter.value.join(",");
        searchData.filter = filterstr;
        searchData.regionOpp = regionOpp.value;

        let snidStr = [];
        Object.keys(snId.value).forEach((key) => {
          snidStr.push(key + ":" + snId.value[key]);
        });
        snidStr = snidStr.join(";");
        //console.log(snidStr);
        searchData.snId = snidStr;
      }
      searchData.outMemberId = proxy.$fun.setUserID();
      res = await proxy.$api.searchFengGoods(searchData);
    } else if (route.query.type == "imgSearch" && route.query.imgUrl) {
      console.log(funshopType);
      let searchData = {
        img_url: imgUrl.value,
        page:
          funshopType == "tmall"
            ? Tmallpage.value
            : funshopType == "1688"
            ? page.value
            : taobaopage.value,
        imageId: route.query.imageId != undefined ? route.query.imageId : "",
        pageSize: funshopType == "1688" ? 50 : 20,
        region: funshopType == "1688" ? region.value : undefined,
        shop_type: funshopType,
      };
      // 筛选只有1688才能用
      if (funshopType == "1688") {
        let searcg_value = searchsort_arr.value.find(
          (sarr) => sarr.value == searchsort.value
        );
        searchsort.value
          ? (searchData.order_by = searcg_value.returnObj_imgSearch)
          : "";
        searchData.price_min = priceStart.value;
        searchData.price_max = priceEnd.value;
        if (xingji.value) {
          filter.value.push(xingji.value);
        }
        let filterstr = filter.value.join(",");
        searchData.filter = filterstr;
      }
      searchData.outMemberId = proxy.$fun.setUserID();
      res = await proxy.$api.imgSearchFengGoods(searchData);
      if (funshopType == "1688") {
        region.value = res.data.picRegionInfo.currentRegion;
        getImageSliceList(res.data.picRegionInfo.yoloCropRegion);
      }
      res.data.result = { result: res.data.data };
    }
    fangdou.value = false;
    loading.value = false;
    if (res.code != 0) {
      return (error.value = true);
    }
    //  首先判断是不是1688链接搜索或者链接搜索，如果是直接跳转商品详情
    if (res.data.data_type == "goodsParticulars") {
      proxy.$store.commit("getsearchInfo", "");
      proxy.to_goods_details(
        res.data.result.goodsId,
        res.data.result.fromPlatform
      );
      return;
    }

    if (funshopType == "1688") {
      if (!res.data.result.result) {
        finished.value = true;
        refreshing.value = false;
        Toast.clear();
        return;
      }
      // 说明是下滑
      if (refreshing.value == true) {
        l688list.value = [...res.data.result?.result, ...l688list.value];
      } else {
        l688list.value = [...l688list.value, ...res.data.result?.result];
      }

      refreshing.value = false;
      if (
        l688list.value.length >= res.data.result.total ||
        l688list.value.length == 0
      ) {
        finished.value = true;
      }
      // 加载状态结束
      loading.value = false;
      Toast.clear();
    } else if (funshopType == "tmall") {
      // 天猫
      if (!res.data.result.result) {
        Tmallfinished.value = true;
        Tmallrefreshing.value = false;
        Toast.clear();
        return;
      }
      if (Tmallrefreshing.value == true) {
        Tmalllist.value = [...res.data.result.result, ...Tmalllist.value];
      } else {
        Tmalllist.value = [...Tmalllist.value, ...res.data.result.result];
      }

      Tmallrefreshing.value = false;
      if (
        Tmalllist.value.length >= res.data.result.total ||
        Tmalllist.value.length == 0
      ) {
        Tmallfinished.value = true;
      }
      Toast.clear();

      // 加载状态结束
      loading.value = false;
    } else {
      // 淘宝
      if (!res.data.result.result) {
        taobaofinished.value = true;
        taobaorefreshing.value = false;
        Toast.clear();

        return;
      }
      if (taobaorefreshing.value == true) {
        taobaolist.value = [...res.data.result.result, ...taobaolist.value];
      } else {
        taobaolist.value = [...taobaolist.value, ...res.data.result.result];
      }

      taobaorefreshing.value = false;
      if (
        taobaolist.value.length >= res.data.result.total ||
        taobaolist.value.length == 0
      ) {
        taobaofinished.value = true;
      }
      Toast.clear();

      // 加载状态结束
      loading.value = false;
    }
  }
};
const havefil = computed(() => {
  let fsnId = false;
  Object.keys(snId.value).forEach((key) => {
    if (snId.value[key]) {
      fsnId = true;
    }
  });
  if (
    priceStart.value ||
    priceEnd.value ||
    filter.value.length ||
    fsnId ||
    regionOpp.value
  ) {
    return true;
  }
  return false;
});
const show_productScreening = ref(true);
// 监听滚动事判断是否显示筛选
const isshow_productScreening = () => {
  let to_top = 0;
  let to_bottom = 0;
  let after_scrollData = 0;
  document,
    addEventListener("scroll", () => {
      if (proxy.$route.name != "CommoditySearch") return;
      let osTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (after_scrollData < osTop) {
        //下滚
        let rolling_distance = Math.abs(to_bottom - osTop);
        if (rolling_distance > 500) {
          show_productScreening.value = false;
        }
        to_top = osTop;
      } else {
        //上滚
        let rolling_distance = Math.abs(to_top - osTop);
        if (rolling_distance > 500) {
          show_productScreening.value = true;
        }
        to_bottom = osTop;
      }
      //防止滚动高度不够的情况下页面滚动到顶部却没有显示筛选
      // if (osTop < 400) {
      //   show_productScreening.value = true;
      // }
      setTimeout(() => {
        after_scrollData = osTop;
      }, 0);
    });
};
isshow_productScreening();
// eslint-disable-next-line no-unused-vars
const getImageSliceList = (region) => {
  if (imgUrlList.value.length) return;
  imgUrlList.value = [];

  // 假设 res.data.result 是一个数组，包含每个区域的坐标
  const results = region; // [{ left, right, top, bottom }, ...]

  // 创建图片对象
  const img = new Image();
  img.src = proxy.$route.query.imgUrl; // 设置图片源为 Base64
  img.crossOrigin = "Anonymous";
  img.onload = () => {
    const croppedImages = []; // 存储切割结果

    results.forEach((resultsItem, index) => {
      for (let i in resultsItem) {
        resultsItem[i] = Number(resultsItem[i]);
      }
      const { left, right, top, bottom } = resultsItem; // 获取切割区域
      let width = right - left;
      let height = bottom - top;
      // // 验证切割区域是否合法
      // if (
      //   left < 0 ||
      //   top < 0 ||
      //   width <= 0 ||
      //   height <= 0 ||
      //   left + width > img.width ||
      //   top + height > img.height
      // ) {
      //   console.error(`区域 ${index + 1} 的坐标无效，已跳过`);
      //   return;
      // }

      const croppedCanvas = document.createElement("canvas");
      croppedCanvas.width = width;
      croppedCanvas.height = height;
      const croppedCtx = croppedCanvas.getContext("2d");

      // 切割指定区域
      croppedCtx.drawImage(
        img,
        left,
        top, // 原图起点
        width,
        height, // 原图宽高
        0,
        0, // 目标起点
        width,
        height // 目标宽高
      );
      // console.log(img, croppedCtx);
      // 转为 Base64 并保存到数组
      const dataURL = croppedCanvas.toDataURL("image/png");
      if (dataURL === "data:,") {
        console.error(`区域 ${index + 1} 生成的图片为空`);
      } else {
        croppedImages.push({
          url: dataURL,
          region: `${left},${right},${top},${bottom}`,
        });
        // // 示例：直接在页面上显示切割后的图片
        // const imgElement = document.createElement('img');
        // imgElement.src = dataURL;
        // imgElement.alt = `Cropped Image ${index + 1}`;
        // document.body.appendChild(imgElement); // 将图片插入页面
      }
    });

    imgUrlList.value = croppedImages;
    // 示例：打印所有切割后的图片
    console.log("切割后的图片数组:", croppedImages);
    console.log("获取数组成功");
  };

  img.onerror = (err) => {
    console.error("图片加载失败:", err);
  };
};
// 下拉刷新
const onRefresh = () => {
  if (imgfinished.value == true) {
    Toast(proxy.$fanyi("没有更多了"));
    imgrefreshing.value = false;
    return;
  }
  if (finished.value == true && shoptype.value == "1688") {
    Toast(proxy.$fanyi("没有更多了"));
    refreshing.value = false;
    return;
  }
  if (taobaofinished.value == true && shoptype.value == "taobao") {
    Toast(proxy.$fanyi("没有更多了"));
    taobaorefreshing.value = false;
    return;
  }
  if (Tmallfinished.value == true && shoptype.value == "tmall") {
    Toast(proxy.$fanyi("没有更多了"));
    Tmallrefreshing.value = false;
    return;
  }
  onLoad();
};
const filterProducts = (back_data) => {
  //console.log(back_data);
  if (back_data == "reset") {
    priceEnd.value = "";
    priceStart.value = "";
    regionOpp.value = "";
    filter.value = [];
    xingji.value = "";
    snId.value = {};
  } else if (back_data) {
    priceEnd.value = back_data.priceEnd;
    priceStart.value = back_data.priceStart;
    regionOpp.value = back_data.regionOpp;
    filter.value = back_data.filter;
    xingji.value = back_data.xingji;
    snId.value = back_data.snId;
  }
  refreshing.value = true;
  loading.value = true;
  l688list.value = [];
  page.value = 0;
  Toast.loading({
    message: proxy.$fanyi("加载中..."),
    forbidClick: true,
    loadingType: "spinner",
    duration: 0,
  });
  // 关闭下拉框(因为vant版本问题找不到ref里的关闭事件,所以模拟点击事件关闭下拉框)
  document.getElementsByClassName("productScreening")[0].click();
  window.scrollTo(0, 0);
  onLoad();
};

//----------------------------------------------------------onLoad---------------

if (["krOpp", "jpOpp"].includes(proxy.$route.query.goodsForm)) {
  shoptype.value = "1688";
  regionOpp.value = proxy.$route.query.goodsForm;
}

const to_goods_details = (goodsId, fromPlatform) => {
  let recommendedGoods = false;

  if (regionOpp.value && shoptype.value == 1688) {
    recommendedGoods = true;
    if (location.host.indexOf(".com") !== -1) {
      __bl.sum("通过关键词与图搜访问爆品商品统计");
    }
  }

  let source = "";
  if (proxy.$route.query.keyword) {
    if (proxy.$route.query.CN) {
      source = "classify";
    } else {
      source = "keyword";
    }
  } else if (proxy.$route.query.imgUrl) {
    source = "img";
  }
  proxy.$fun.routerToPage(
    `/ProductDetails?goods_id=${goodsId}&fromPlatform=${fromPlatform}&recommendedGoods=${recommendedGoods}&source=${source}`
  );
};

const clickFilterSum = () => {
  // //console.log(proxy.$fun.isOfficialWeb());
  if (proxy.$fun.isOfficialWeb() && regionOpp.value) {
    __bl.sum("关键词搜索与图片搜索点击爆品筛选数量统计");
  }
};

onLoad();
const setImgUrl = (item, index) => {
  console.log(item);
  imgUrlAct.value = index;
  region.value = item.region;
  imglist.value = [];
  imgpage.value = 0;
  imgfinished.value = false;
  loading.value = true;
  fangdou.value = false;
  refreshing.value = true;
  page.value = 0;
  Toast.loading({
    message: proxy.$fanyi("加载中..."),
    forbidClick: true,
    loadingType: "spinner",
    duration: 0,
  });
  // 关闭下拉框(因为vant版本问题找不到ref里的关闭事件,所以模拟点击事件关闭下拉框)
  document.getElementsByClassName("productScreening")[0].click();
  window.scrollTo(0, 0);
  onLoad();
};
// eslint-disable-next-line no-undef
defineExpose({ noDataShow, shoptype });
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.searchPageHead {
  padding: 9px 30px;
}

.CommoditySearch {
  width: 100%;
  padding: 0 0px;
  min-height: 90vh;
  background-color: #f6f6f6;
  // padding-bottom: 120px;
  .sdfsd {
    position: sticky;
    top: 99px;
    z-index: 10;
    margin-bottom: 20px;
  }
  .technicalSupport {
    padding: 0px 30px;
    margin: 20px 0;
    font-size: 20px;
    color: #999999;
    line-height: 1;
    background-color: #f6f6f6;
  }
  .CommoditySearch-keyword {
    position: sticky;
    left: 0;
    width: 100%;
    top: 98px;
    height: 80px;
    background: white;
    display: flex;
    z-index: 2;
    padding-left: 188px;
    padding-right: 188px;
    align-items: center;
    justify-content: space-between;
    .opt {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 0;
      white-space: nowrap;
    }
    .left-1688 {
      font-size: 36px;
      font-weight: 600;
      color: #010101;
    }

    .taobao {
      font-size: 36px;
      font-weight: 600;
      color: #010101;
    }

    span {
      position: absolute;
      width: 50px;
      height: 5px;
      background: #b4272b;
      border-radius: 3px;
      bottom: 10px;
      left: 165px;
      transition: all 0.5s;
    }

    .tabposition {
      left: 530px;
    }
    .tabposition_Tmall {
      left: 537px;
    }
    .redcolor {
      font-size: 36px;
      font-weight: 600;
      color: #b4272b;
    }
  }
  .productScreening {
    height: 80px;
    padding: 10px 30px;
    background-color: white;
    display: flex;

    .ScreenBtnList {
      display: flex;
      align-items: center;
      .ScreenBtn {
        padding: 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        background: #f6f6f6;
        border-radius: 6px 6px 0 0;
        margin-right: 20px;
        span {
          font-size: 24px;
        }
        img {
          margin-left: 10px;
        }
      }
      .ScreenBtn.active {
        color: #b4272b;
      }
      .ScreenBtn.comprehensiveactive {
        color: #b4272b;
      }
      .ScreenBtn.priceFilteringactive {
        color: #b4272b;
      }
    }
    .van-dropdown-menu {
      :deep().van-dropdown-menu__bar {
        height: 0;
      }
      :deep().van-dropdown-item__content {
        margin: 0 !important;
        width: 100%;
        border-radius: 0;
        background-color: #f6f6f6;
        padding: 0 10px;
      }
      :deep().van-dropdown-menu__title {
        opacity: 0;
      }
      .comprehensiveBtnlistBox {
        justify-content: space-between;
        .comprehensiveBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60px;
          background: #ffffff;
          margin: 10px 20px;
          border-radius: 6px;
          font-size: 24px;
          padding: 0 20px;
          min-width: 208px;
          border: solid 2px white;
        }
        .comprehensiveBtn.active {
          border: 2px solid #b4272b;
          border: 2px solid #b4272b;
          color: #b4272b;
          border-radius: 6px;
        }
      }
    }
    .searchImageBox {
      margin-left: auto;
      width: 60px;
      height: 60px;
      border: 1px solid #b4272b;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      .van-image {
        max-width: 60px;
      }
    }
  }
  // 商品列表
  .shoplist {
    width: 100%;
    // min-height: 700px;

    .van-list {
      // display: flex;
      // flex-direction: row;
      // justify-content: space-between;
      padding: 0 30px;
      .list {
        max-width: 690px;
        display: flex;
        // flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .item {
        margin-bottom: 15px;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        overflow: hidden;
        width: 336px;
        min-height: 472px;
        padding-bottom: 12px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        border-radius: 6px;

        .top-img {
          width: 336px;
          height: 336px;
          background: #ffffff;

          .van-image {
            width: 336px;
            height: 336px;
            background: #ffffff;
          }
        }

        .shoptext {
          width: 100%;
          margin: 19px 0;
          padding-left: 20px;
          padding-right: 11px;
          word-break: break-all;
          text-overflow: ellipsis;
          font-size: 24px;

          font-weight: 400;
          line-height: 26px;
          height: 52px;
          color: #000000;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          /* 超出几行省略 */
          overflow: hidden;
          .icon1688 {
            width: 61px;
            height: 23px;
            background: #ff4000;
            border-radius: 2px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
            img {
              width: 42px;
            }
          }
        }
        .price_and_xiaoshou {
          // display: flex;
          // align-items: flex-end;
          // justify-content: space-between;
          // flex-wrap: wrap;
          padding: 0 15px 0 20px;
          margin-bottom: 25px;
          .price {
            padding-left: 0;
            font-size: 24px;
            display: flex;
            align-items: flex-end;
            font-weight: 600;
            color: #b4272b;

            span {
              font-size: 28px;
              font-weight: 600;
              color: #b4272b;
            }
          }
          .yuexiaoshou {
            font-size: 18px;
            line-height: 1;
            color: #999999;
            font-weight: 400;
            margin-top: 10px;
          }
        }
        .goods_tag_list {
          display: flex;
          padding: 0 20px;

          .tag_item {
            background: #ffefef;
            padding: 0 8px;
            color: #ff4000;
            margin-right: 10px;
            font-size: 20px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .repurchasing {
          margin-left: 20px;
          margin-top: 10px;
          min-width: 154px;
          width: max-content;
          height: 30px;
          padding: 0 8px;
          background: #f6f6f6;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          b {
            font-size: 20px;
            color: #b4272d;
          }
        }
        .price {
          padding-left: 20px;
          font-size: 24px;

          font-weight: 600;
          color: #b4272b;

          span {
            font-size: 28px;

            font-weight: 600;
            color: #b4272b;
          }
        }
      }
    }
  }
}
:deep().van-ellipsis {
  display: none;
}
.nfdsjjk {
  display: none;
}
.nodatadd {
  display: block;
}

.imgserch {
  width: 100%;
  background: #f8f8f8;
  padding: 0 30px;
  overflow: auto;

  .img-box {
    display: flex;
    width: calc(100vw - 60px);
    overflow-x: scroll;

    .van-image {
      width: 120px;
      min-width: 120px;
      height: 120px;
      background: #ffffff;
      border-radius: 6px;
      margin-right: 20px;
      overflow: hidden;

      &::after {
        content: 33;
        display: block;
        width: 10px;
        height: 10px;
      }
    }

    .van-image:last-child {
      margin-right: 0;
    }

    /deep/ .van-image.active {
      position: relative;
      border: 2px solid #b4272b;
      overflow: visible;
    }
  }
}
</style>
